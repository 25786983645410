
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$tesoreria-primary: (
  50: #ffeaed,
  100: #ffeaed,
  200: #f29796,
  300: #e96e6d,
  400: #f34b48,
  500: #f8382b,
  600: #e92c2b,
  700: #d72025,
  800: #ca161e,
  900: #bb0211,
 contrast: (
   50: rgba(black, 0.87),
   100: rgba(black, 0.87),
   200: rgba(black, 0.87),
   300: rgba(black, 0.87),
   400: rgba(black, 0.87),
   500: rgba(white, 1),
   600: rgba(white, 1),
   700: rgba(white, 1),
   800: rgba(white, 1),
   900: rgba(white, 1)
 )
);

$tesoreria-accent: (
  50: #ffeaed,
  100: #ffeaed,
  200: #f29796,
  300: #e96e6d,
  400: #f34b48,
  500: #f8382b,
  600: #e92c2b,
  700: #d72025,
  800: #ca161e,
  900: #bb0211,
 contrast: (
   50: rgba(black, 0.87),
   100: rgba(black, 0.87),
   200: rgba(black, 0.87),
   300: rgba(white, 1),
   400: rgba(white, 1),
   500: rgba(white, 1),
   600: rgba(white, 1),
   700: rgba(white, 1),
   800: rgba(white, 1),
   900: rgba(white, 1)
 )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tesoreria-front-primary: mat.define-palette($tesoreria-primary, 400);
$tesoreria-front-accent: mat.define-palette($tesoreria-accent, 400);

// The warn palette is optional (defaults to red).
$tesoreria-front-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$tesoreria-front-theme: mat.define-light-theme((
  color: (
    primary: $tesoreria-front-primary,
    accent: $tesoreria-front-accent,
    warn: $tesoreria-front-warn,
  )
));



// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($tesoreria-front-theme);


html, body { height: 100%; background: rgba(231, 237, 244, 0.6); }
body { margin: 0; font-family: Poppins, Roboto, "Helvetica Neue", sans-serif; }
* { font-family: Poppins, Roboto, "Helvetica Neue", sans-serif; }
