/* You can add global styles to this file, and also import other style files */
@import 'assets/css/bootstrap.css'

app-login
  width: 100% !important

mat-card
  box-shadow: none !important
  filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.05))

/* width */
::-webkit-scrollbar
  width: 5px
  height: 5px

/* Track */
::-webkit-scrollbar-track
  background: none

/* Handle */
::-webkit-scrollbar-thumb
  background: #c1c1c1
  border-radius: 5px

/* Handle on hover */
::-webkit-scrollbar-thumb:hover
  background: #8d8d8d